import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import classnames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';

import * as echarts from 'echarts';
import type { API } from '@easygo/service/src/apis';
import dayjs from 'dayjs';
import { useEventListener } from 'ahooks';
import BoxPriceTrendTable from '../box-price-trend-table';
import { RentBoxProductContext } from '../../contexts/rent-box';

export type Mode = 'dark' | 'light';

function generateChartOption(priceMarks: API.HistoryPriceMarkVO[], mode: Mode = 'dark'): echarts.EChartsOption {
  const color = mode === 'dark' ? '#fff' : '#2C334D';
  const legendColor = mode === 'dark' ? 'rgba(255, 255, 255, 0.66)' : '#2C334D';
  const yAxisColor = mode === 'dark' ? 'rgba(255, 255, 255, 0.20)' : '#2C334D';
  const dateFormator = (priceMark: API.HistoryPriceMarkVO) => dayjs(priceMark.time!, 'YYYYMMDD').format('M/D');

  return {
    title: {
      text: i18n?.t('价格走势'),
      textStyle: {
        color,
        fontSize: '14px',
        fontFamily: 'PingFangSC-Medium, PingFang SC',
      },
      left: '12px',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const dataIndex = params[0].dataIndex;
        const time = dayjs(priceMarks[dataIndex].time!, 'YYYYMMDD').format('YYYY-MM-DD');

        return `<div style="width:160px;font-size:14px;color: #1A1E2E;padding:6px 0 12px 6px;font-weight: bold;">
          <div>${time}</div>
          ${params
            .map(
              (item: any, index: number) =>
                `<div style="font-size:12px;color: #2C334D;margin-top:8px;font-weight:400;">${item['seriesName']}: $${item['data'][1]}<div>`
            )
            .join('')}
        </div>`;
      },
    },
    legend: {
      data: ['20GP', '40GP'],
      right: '12px',
      textStyle: {
        color: legendColor,
        fontSize: '14px',
        fontFamily: 'PingFangSC-Medium, PingFang SC',
      },
      itemStyle: {
        opacity: 0,
      },
      selectedMode: false,
    },
    grid: {
      left: '16px',
      right: '16px',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'PingFangSC-Medium, PingFang SC',
        opacity: 0.4,
        color,
      },
      data: priceMarks.map(dateFormator),
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
      },
      axisLabel: {
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'PingFangSC-Medium, PingFang SC',
        opacity: 0.4,
        color,
      },
      splitLine: {
        lineStyle: {
          type: [5, 10],
          dashOffset: 5,
          color: yAxisColor,
        },
      },
    },
    series: [
      {
        name: '20GP',
        type: 'line',
        smooth: true,
        itemStyle: {
          color: '#94A338',
        },
        data: priceMarks.filter((item) => item.price20gp).map((item) => [dateFormator(item), Number(item.price20gp)]),
      },
      {
        name: '40GP',
        type: 'line',
        smooth: true,
        itemStyle: {
          color: '#21A099',
        },
        data: priceMarks.filter((item) => item.price40gp).map((item) => [dateFormator(item), Number(item.price40gp)]),
      },
    ],
  };
}

export interface PriceTrendChartOption {
  /** 提箱城市id */
  pickupCityId?: string;
  /** 提箱城市 */
  pickupCity?: string;
  /** 还箱城市id */
  dropOffCityId?: string;
  /** 还箱城市 */
  dropOffCity?: string;
  items: API.HistoryPriceMarkVO[];
}

export interface BoxPriceTrendChartProps {
  className?: string;
  style?: React.CSSProperties;
  options: PriceTrendChartOption;
  showTable?: boolean;
  onOrder?: (item: API.PriceMarkDetailVO) => void;
  mode?: Mode;
  listPriceMarkDetailApi?: (args: any) => Promise<any>;
}

const BoxPriceTrendChart: React.FC<BoxPriceTrendChartProps> = ({
  className,
  style,
  options: { pickupCityId, pickupCity, dropOffCityId, dropOffCity, items },
  showTable = false,
  onOrder,
  mode,
  listPriceMarkDetailApi,
}) => {
  const rentBoxProductContext = useContext(RentBoxProductContext);

  const listPriceMarkDetail = listPriceMarkDetailApi || rentBoxProductContext!.listPriceMarkDetail;
  const chartRef = useRef<HTMLDivElement>(null);
  const myChart = useRef<echarts.ECharts>();
  const [priceMarkDetailItems, setPriceMarkDetailItems] = useState<API.PriceMarkDetailVO[]>([]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    myChart.current = echarts.init(chartRef.current);
  }, []);

  useEffect(() => {
    const option = generateChartOption(items, mode);
    option && myChart.current?.setOption(option);
  }, [items, mode]);

  useEffect(() => {
    if (!showTable || !pickupCityId || !dropOffCityId) {
      return;
    }

    listPriceMarkDetail({ vo: { pickupCityId, dropOffCityId } }).then((res) => {
      setPriceMarkDetailItems(res || []);
    });
  }, [listPriceMarkDetail, showTable, pickupCityId, dropOffCityId]);

  useEventListener(
    'resize',
    () => {
      myChart.current?.resize();
    },
    { target: window }
  );

  return (
    <div className={classnames(styles['box-price-trend-chart'], className, { [styles.light]: mode === 'light' })} style={style}>
      {mode === 'light' ? (
        <div className={styles['chart-wrap']} ref={chartRef}></div>
      ) : (
        <>
          <div className={classnames(styles.title)}>{`${pickupCity}-${dropOffCity}`}</div>
          <div className={styles['chart-wrap']} ref={chartRef}></div>
          {showTable && priceMarkDetailItems.length > 0 && (
            <BoxPriceTrendTable items={priceMarkDetailItems} onOrder={onOrder} style={{ marginTop: 16 }} />
          )}
        </>
      )}
    </div>
  );
};

export default BoxPriceTrendChart;
