import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import classnames from 'classnames';
import React from 'react';
import type { API } from '@easygo/service/src/apis';

const Header: React.FC = () => {
  return (
    <div className={styles['single-header']}>
      <div className={classnames(styles['header-item'], styles.city)}>{i18n?.t('提还箱城市')}</div>
      <div className={classnames(styles['header-item'], styles['box-type'])}>{i18n?.t('箱型')}</div>
      <div className={classnames(styles['header-item'], styles.price)}>{i18n?.t('行情价($)')}</div>
      <div className={classnames(styles['header-item'], styles.price)}>{i18n?.t('易畅价($)')}</div>
      <div className={classnames(styles['header-item'], styles.ops)}>{i18n?.t('操作')}</div>
    </div>
  );
};

export interface PriceItemProps {
  className?: string;
  item: API.PriceMarkDetailVO;
  onOrder: () => void;
}
const PriceItem: React.FC<PriceItemProps> = ({ item, className, onOrder }) => {
  return (
    <div className={classnames(styles['box-price-item'], className)}>
      <div className={classnames(styles['price-cell'], styles.city)}>{`${item.pickupCity}-${item.dropOffCity}`}</div>
      <div className={classnames(styles['price-cell'], styles['box-type'])}>{item.boxType}</div>
      <div className={classnames(styles['price-cell'], styles.price)}>{item.marketPrice || '-'}</div>
      <div className={classnames(styles['price-cell'], styles['platform-price'])}>
        {item.platformPrice ? Number(item.platformPrice).toFixed(0) : '-'}
      </div>
      <div className={classnames(styles['price-cell'], styles.ops)}>
        <span className={styles.btn} onClick={onOrder}>
          {i18n?.t('去下单')}
        </span>
      </div>
    </div>
  );
};

export interface BoxPriceListProps {
  style?: React.CSSProperties;
  items: API.PriceMarkDetailVO[];
  onOrder?: (item: API.PriceMarkDetailVO) => void;
}
const BoxPriceTrendTable: React.FC<BoxPriceListProps> = ({ style, items, onOrder }) => {
  return (
    <div className={styles['box-price-table']} style={style}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.body}>
        <div className={styles['box-prices-wrap']}>
          {items.map((item, index) => (
            <PriceItem key={index} item={item} onOrder={() => onOrder && onOrder(item)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BoxPriceTrendTable;
