import type { API } from '@easygo/service/src/apis';
import React from 'react';

export interface RentBoxProductContextType {
  queryActivityProducts: (body: API.QueryContainerLeasingVo) => Promise<API.QueryContainerLeasingProductsResponseVo>;
  seckillQueryActivityProducts: (body: API.QueryActivityProductVo) => Promise<API.QueryContainerLeasingProductsResponseVo>;
  queryRecommendedProducts: (body: API.QueryRecommendedProductVo) => Promise<API.QueryContainerLeasingProductsResponseVo>;
  listHistoryPriceMarkIfNotExitProduct: (
    params: API.OperationIdSalesgatewayContainerLeasingPriceMarkControllerListHistoryPriceMarkIfNotExitProductParams
  ) => Promise<API.HistoryPriceMarkVO[]>;
  enablePriceMark: () => Promise<boolean>;
  listPriceMarkDetail: (
    params: API.OperationIdSalesgatewayOpenContainerLeasingPriceMarkControllerListPriceMarkDetailParams
  ) => Promise<API.PriceMarkDetailVO[]>;
}

export const RentBoxProductContext = React.createContext<RentBoxProductContextType | undefined>(undefined);
