import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import classnames from 'classnames';
import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import BetterScroll from 'better-scroll';
import { Spin } from 'antd';
import type { API } from '@easygo/service/src/sale';
import { IconFont } from '@easygo/components';
import BigNumber from 'bignumber.js';

const enum TrendTag {
  down = 0,
  up = 1,
  nochange = 2,
}

export interface TrendIconProps {
  tag: TrendTag;
}
const TrendIcon: React.FC<TrendIconProps> = ({ tag }) => {
  switch (tag) {
    case TrendTag.up:
      return <IconFont type="easygo-icon-a-bianzu80" style={{ fontSize: 16, color: 'rgba(231, 121, 121, 1)' }} />;
    case TrendTag.down:
      return <IconFont type="easygo-icon-a-bianzu81" style={{ fontSize: 16, color: 'rgba(0, 166, 159, 1)' }} />;
    default:
      return <span>-</span>;
  }
};

const SingleHeader: React.FC = () => {
  return (
    <div className={styles['single-header']}>
      <div className={classnames(styles['header-item'], styles.city)}>{i18n?.t('提-还箱城市')}</div>
      <div className={classnames(styles['header-item'], styles.price)}>20GP</div>
      <div className={classnames(styles['header-item'], styles.price)}>40GP</div>
      <div className={classnames(styles['header-item'], styles.icon)}>{i18n?.t('行情趋势')}</div>
    </div>
  );
};

export interface PriceItemProps {
  className?: string;
  item: API.PriceMarkVO;
  onClick: () => void;
}
const PriceItem: React.FC<PriceItemProps> = ({ item, className, onClick }) => {
  return (
    <div className={classnames(styles['box-price-item'], className)} onClick={onClick}>
      <div className={classnames(styles['price-cell'], styles.city)}>{`${item.pickupCity}-${item.dropOffCity}`}</div>
      <div className={classnames(styles['price-cell'], styles.price)}>{item.price20gp ? `$ ${new BigNumber(item.price20gp).toFixed(2)}` : '-'}</div>
      <div className={classnames(styles['price-cell'], styles.price)}>{item.price40gp ? `$ ${new BigNumber(item.price40gp).toFixed(2)}` : '-'}</div>
      <div className={classnames(styles['price-cell'], styles.icon)}>
        <TrendIcon tag={item.priceTrend as TrendTag} />
      </div>
    </div>
  );
};

export interface Ins {
  bscroll?: BetterScroll;
}
export interface BoxPriceListProps {
  loading: boolean;
  items: API.PriceMarkVO[];
  onPullingUp: () => Promise<any>;
  onSelected?: (item: API.PriceMarkVO, index: number) => void;
  selectedIndex?: number;
}
const BoxPriceList = React.forwardRef<Ins, BoxPriceListProps>(({ loading, items, selectedIndex, onSelected, onPullingUp }, ref) => {
  const scrollWrapRef = useRef<HTMLDivElement>(null);
  const isPullingRef = useRef(false);
  const bscrollRef = useRef<BetterScroll>();
  const handlerPullingUpRef = useRef<any>();

  const handlerPullingUp = useCallback(async () => {
    if (isPullingRef.current) {
      return;
    }
    isPullingRef.current = true;
    await onPullingUp();
    bscrollRef.current?.finishPullUp();
    bscrollRef.current?.refresh();
    isPullingRef.current = false;
  }, [onPullingUp]);

  useEffect(() => {
    if (!scrollWrapRef.current) {
      return;
    }
    bscrollRef.current = new BetterScroll(scrollWrapRef.current, {
      mouseWheel: true,
      pullUpLoad: true,
    });

    bscrollRef.current.on('pullingUp', handlerPullingUp);
    handlerPullingUpRef.current = handlerPullingUp;

    return () => {
      bscrollRef.current && bscrollRef.current.off('pullingUp', handlerPullingUpRef.current);
      handlerPullingUpRef.current = undefined;
    };
  }, [handlerPullingUp]);

  useEffect(() => {
    bscrollRef.current?.refresh();
  }, [items]);

  useImperativeHandle(ref, () => ({
    bscroll: bscrollRef.current,
  }));

  return (
    <div className={styles['box-price-list']}>
      <div className={styles.header}>
        <SingleHeader />
        <SingleHeader />
      </div>

      <div className={styles.body}>
        <div className={styles['scroll-wrap']} ref={scrollWrapRef}>
          <div className={styles['box-prices-wrap']}>
            {items.map((item, index) => (
              <PriceItem
                key={index}
                item={item}
                className={classnames({ [styles.actived]: index === selectedIndex })}
                onClick={() => onSelected && onSelected(item, index)}
              />
            ))}
            {items.length % 2 === 1 && <div className={styles['box-price-item']} />}
            {/* <Spin spinning={loading} style={{ width: '100%' }}></Spin> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default BoxPriceList;
