import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import classNames from 'classnames';
import React, { useContext } from 'react';
import type { SelectProps } from 'antd';
import { useBoolean } from 'ahooks';
import { BaseSearchSelect, IconFont } from '@easygo/components';
import { sharedApiConfig } from '@easygo/config/commonApiConfig';
import { MarineOrderContext } from '../../orders/marine/context';

export enum AreaType {
  city = 'CITY',
  station = 'STATION',
  port = 'PORT',
}

const AreaTypeLabelMap = {
  [AreaType.city]: i18n?.t('城市'),
  [AreaType.station]: i18n?.t('车站'),
  [AreaType.port]: i18n?.t('港口'),
};

const AreaTypeIconMap = {
  [AreaType.city]: {
    default: 'easygo-icon-chengshi-weixuanzhong',
    active: 'easygo-icon-chengshixuanzhong',
  },
  [AreaType.station]: {
    default: 'easygo-icon-a-chezhanweixuanzhong',
    active: 'easygo-icon-a-chezhanxuanzhong',
  },
  [AreaType.port]: {
    default: 'easygo-icon-a-gangkouweixuanzhong',
    active: 'easygo-icon-a-gangkouxuanzhong',
  },
};

export interface AreaOptionItem {
  id: string;
  nameZh: string;
  nameEn: string;
  areaType: AreaType;
}

interface SelectAreaItemProps<T> {
  item: T;
  onSelected: (item: T) => void;
}

function SelectAreaItem<T extends AreaOptionItem>({ item, onSelected }: SelectAreaItemProps<T>) {
  const [isActive, { setTrue: setActive, setFalse: setNoActive }] = useBoolean(false);
  return (
    <div className={styles['selector-item']} onClick={() => onSelected(item)} onMouseDown={setActive} onMouseUp={setNoActive}>
      <div className={styles.left}>
        <IconFont type={isActive ? AreaTypeIconMap[item.areaType]?.active : AreaTypeIconMap[item.areaType]?.default} style={{ fontSize: 24 }} />
        <div className={styles['type-label']}>{AreaTypeLabelMap[item.areaType]}</div>
      </div>
      <div className={styles.right}>
        <div className={styles['label-top']}>{item.nameZh}</div>
        <div className={styles['label-bot']}>{item.nameEn}</div>
      </div>
    </div>
  );
}

export interface SelectAreaProps<T> extends SelectProps {
  value?: T;
  onChange?: (value: T | undefined) => void;
}
export function SelectCityPort<T extends AreaOptionItem>({ onChange, ...restProps }: SelectAreaProps<T>) {
  const { isAdmin, harbourRequest } = useContext(MarineOrderContext);
  const searchPort = async (keyword: string) => {
    const harbourRequestReal = isAdmin ? harbourRequest : sharedApiConfig.apiConfig.sinoPageHarbour;
    const res = await harbourRequestReal({ query: { nameFuzzy: keyword.trim(), size: -1 } });
    return (res?.records ?? []).map((item) => {
      return {
        ...item,
        nameZh: `${item.nameZh}`,
        nameEn: `${item.nameEn},${item.code ?? ''}`,
        areaType: AreaType.port, // 默认展示港口
      };
    }) as any[];
  };

  return (
    <BaseSearchSelect<any>
      popupClassName={classNames(styles['area-select-dropdown'], restProps?.popupClassName)}
      onChange={onChange}
      onSearch={searchPort}
      dropdownItemRender={(item, onSelected) => <SelectAreaItem item={item} onSelected={onSelected} />}
      selectItemRender={(item) => item.nameZh}
      {...restProps}
    />
  );
}
