import classNames from 'classnames';
import styles from './index.module.less';
import React from 'react';

export interface LineFilterItem {
  label: string;
  key: string;
}

export interface LineFilterProps {
  className?: string;
  style?: React.CSSProperties;
  activeKey?: string;
  items: LineFilterItem[];
  onchange: (activeKey: string) => void;
}
const LineFilter: React.FC<LineFilterProps> = ({ className, style, activeKey, items, onchange }) => {
  return (
    <div className={classNames(styles['box-price-line-filter'], className)} style={style}>
      {items.map((item) => (
        <span
          key={item.key}
          className={classNames(styles['filter-item'], { [styles.actived]: activeKey === item.key })}
          onClick={() => onchange(item.key)}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
};

export default LineFilter;
