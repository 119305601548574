import { i18n } from '@easygo/i18n';
import styles from './index.module.less';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Empty, Image } from 'antd';
import LineFilter from './line-filter';
import BoxPriceList from './price-list';
import { useMemoizedFn, useRequest } from 'ahooks';
import type { API } from '@easygo/service/src/apis';
import { SelectAddress } from './select-city';
import { AreaType } from '../select-city-port';
import { easygoTracker } from '@easygo/components';

const FIRST_PAGE_SIZE = 50;
const PAGE_SIZE_ON_PULLING = 24;
const DEFAULT_SEARCH_PARAMS: API.QueryPriceMarkPageVO = { index: 0, pageSize: FIRST_PAGE_SIZE };

interface City {
  name: string;
  id: string;
}

export interface BoxPriceTrendOption {
  pickCityName?: string;
  pickCityId?: string;
  dropCityName?: string;
  dropCityId?: string;
}

export interface BoxPriceTrendProps {
  className?: string;
  style?: React.CSSProperties;
  option?: BoxPriceTrendOption;
  onSelectedPriceMark: (item?: API.PriceMarkPageVO) => void;
  listCoreLineReq: any;
  pagePriceMark: any;
}

const BoxPriceTrend: React.FC<BoxPriceTrendProps> = ({ className, style, option = {}, onSelectedPriceMark, listCoreLineReq, pagePriceMark }) => {
  const [loading, setLoading] = useState(false);

  const pickCityRef = useRef<any>();
  const dropCityRef = useRef<any>();

  const indexRef = useRef(0);

  const [hasMore, setHasMore] = useState(false);
  const [priceItems, setPriceItems] = useState<API.PriceMarkPageVO[]>([]);

  const [selectedLineKey, setSelectedLineKey] = useState<string>();

  const [selectedPriceMarkIndex, setSelectedPriceMarkIndex] = useState<number>();

  const [selectedPickCity, setSelectedPickCity] = useState<City>();

  const [selectedDropCity, setSelectedDropCity] = useState<City>();

  useEffect(() => {
    const { pickCityName, pickCityId, dropCityName, dropCityId } = option;
    if (pickCityId) {
      pickCityRef.current.setSelect({
        id: pickCityId,
        nameZh: pickCityName,
        nameEn: pickCityName,
        areaType: AreaType.city,
      });
      setSelectedPickCity({
        id: pickCityId,
        name: pickCityName!,
      });
    }

    if (dropCityId) {
      dropCityRef.current.setSelect({
        id: dropCityId,
        nameZh: dropCityName,
        nameEn: dropCityName,
        areaType: AreaType.city,
      });
      setSelectedDropCity({
        id: dropCityId,
        name: dropCityName!,
      });
    }
  }, [option]);

  const { data: lineItems } = useRequest(() => listCoreLineReq().then((res) => (res || []).map((item) => ({ label: item, key: item }))), {
    onSuccess: (items) => {
      if (items.length === 0) {
        return;
      }

      if (selectedPickCity || selectedDropCity) {
        onSearch({ ...DEFAULT_SEARCH_PARAMS, pickupCityId: selectedPickCity?.id, dropOffCityId: selectedDropCity?.id });
      } else {
        setSelectedLineKey(items[0].key);
        onSearch({ ...DEFAULT_SEARCH_PARAMS, line: items[0].key });
      }
    },
  });

  const bscrollRef = useRef<any>();

  const onSelectedLineKeyChange = useMemoizedFn((selectedLineKey: string) => {
    onSearch({ ...DEFAULT_SEARCH_PARAMS, pickupCityId: selectedPickCity?.id, dropOffCityId: selectedDropCity?.id, line: selectedLineKey });
  });

  const onNoMore = useMemoizedFn(() => {
    if (!lineItems || !selectedLineKey) {
      return;
    }

    indexRef.current = 0;
    setHasMore(true);
    const curLineIndex = lineItems.findIndex((line) => line.key === selectedLineKey);
    let newLineKey: string;
    if (curLineIndex === -1 || curLineIndex >= lineItems.length - 1) {
      newLineKey = lineItems[0].key;
    } else {
      newLineKey = lineItems[curLineIndex + 1].key;
    }

    setSelectedLineKey(newLineKey);
    onSearch({ ...DEFAULT_SEARCH_PARAMS, pickupCityId: selectedPickCity?.id, dropOffCityId: selectedDropCity?.id, line: newLineKey });
  });

  const onSearch = async (param: API.QueryPriceMarkPageVO = DEFAULT_SEARCH_PARAMS) => {
    setLoading(true);
    indexRef.current = 0;
    const res = await pagePriceMark({ vo: param }).catch(() => undefined);
    if (res && res.length > 0) {
      setPriceItems(res);
      setSelectedPriceMarkIndex(0);
      setHasMore(res.length >= FIRST_PAGE_SIZE);
      indexRef.current = res[res.length - 1].index!;
    } else {
      setHasMore(false);
      setPriceItems([]);
      setSelectedPriceMarkIndex(undefined);
    }
    setTimeout(() => {
      bscrollRef.current?.bscroll?.scrollTo(0, 0);
      bscrollRef.current?.bscroll?.refresh();
    }, 20);
    setLoading(false);
  };

  const onLoadMore = useMemoizedFn(async () => {
    if (!hasMore) {
      onNoMore();
      return;
    }
    setLoading(true);

    const res = await pagePriceMark({
      vo: { index: indexRef.current, pageSize: PAGE_SIZE_ON_PULLING, line: selectedLineKey },
    }).catch(() => undefined);
    if (res && res.length > 0) {
      setPriceItems((priceItems) => [...priceItems, ...res]);
      setHasMore(res.length >= PAGE_SIZE_ON_PULLING);
      indexRef.current = res[res.length - 1].index!;
    } else {
      onNoMore();
    }

    setLoading(false);
  });

  useEffect(() => {
    if (priceItems && typeof selectedPriceMarkIndex !== 'undefined' && selectedPriceMarkIndex < priceItems.length) {
      onSelectedPriceMark(priceItems[selectedPriceMarkIndex]);
    } else {
      onSelectedPriceMark(undefined);
    }
  }, [onSelectedPriceMark, priceItems, selectedPriceMarkIndex]);

  return (
    <div className={classnames(styles['box-price-trend'], className)} style={style}>
      <div className={styles.search}>
        <SelectAddress
          ref={pickCityRef}
          placeholder={i18n?.t('提箱城市')}
          onChange={(item) => setSelectedPickCity(item ? { id: item.id, name: item.nameZh } : undefined)}
        />
        <SelectAddress
          ref={dropCityRef}
          placeholder={i18n?.t('还箱城市')}
          onChange={(item) => setSelectedDropCity(item ? { id: item.id, name: item.nameZh } : undefined)}
        />
        <Button
          type="primary"
          onClick={() => {
            easygoTracker('click_site_box_overview_search', {
              pickupCity: selectedPickCity?.name || '',
              dropoffCity: selectedDropCity?.name || '',
            });
            setSelectedLineKey(undefined);
            onSearch({
              ...DEFAULT_SEARCH_PARAMS,
              pickupCityId: selectedPickCity?.id,
              dropOffCityId: selectedDropCity?.id,
            });
          }}
        >
          {i18n?.t('搜索')}
        </Button>
      </div>

      <div className={styles.content}>
        <LineFilter
          className={styles.filter}
          items={lineItems || []}
          activeKey={selectedLineKey}
          onchange={(lineKey) => {
            setSelectedLineKey(lineKey);
            onSelectedLineKeyChange(lineKey);
          }}
        />
        <div className={styles['price-list']}>
          {loading || (priceItems && priceItems.length > 0) ? (
            <BoxPriceList
              ref={bscrollRef}
              loading={loading}
              items={priceItems || []}
              onPullingUp={onLoadMore}
              onSelected={(_, index) => setSelectedPriceMarkIndex(index)}
              selectedIndex={selectedPriceMarkIndex}
            />
          ) : (
            <Empty
              image={<Image preview={false} src="/images/status/product-empty.svg" alt="" width={200} height={120} />}
              imageStyle={{
                marginTop: 82,
                marginBottom: 35,
              }}
              style={{ marginBottom: 82 }}
              description={<span style={{ color: 'rgba(255, 255, 255, 0.60)' }}>{i18n?.t('暂无匹配线路')}</span>}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoxPriceTrend;
